var showMobileMaxWidth = 1150;
var menuLeft            = $('.menu-left li.menu-left-item');
var menuRight           = $('.menu-right li.menu-right-item');
var mainNavLeft         = $('.main-nav ul.menu-left');
var mainNavRight        = $('.main-nav ul.menu-right');
var mobileNavPanel      = $('.mobile-nav');
var mobileNav           = $('.mobile-nav ul');

function adaptNav() {
    var width = $(window).width();
    
    if(width <= showMobileMaxWidth) {
        menuLeft.appendTo(mobileNav);
        menuRight.appendTo(mobileNav);
    } else {
        menuLeft.appendTo(mainNavLeft);
        menuRight.appendTo(mainNavRight);
    }
}

function scrollToAnchor(aid){
    if(aid == "top") {
        var topscroll = 0;
    } else {
        var section = $("section[class='"+ aid +"']");
        var topscroll = section.offset().top;
    }
    $('html,body').animate({scrollTop: topscroll},'slow');
}

function showTop() {
    var st = window.pageYOffset;

    if (st > 320) {
      $('.back-to-top').fadeIn();
    } else {
      $('.back-to-top').fadeOut();
    }
}


$(document).ready(function() {
    adaptNav();
    showTop();
    $('.hamburger li a').click(function(e) {
        e.preventDefault();
        mobileNavPanel.addClass('open');
    });
    $('li.close a').click(function(e) {
        e.preventDefault();
        mobileNavPanel.removeClass('open');
    });
    $('.home .menu-left a, .home .menu-right a').click(function(e) {
        e.preventDefault();
        var link = $(this).attr('href').substr(1);
        scrollToAnchor(link);
    });
    $('.menu-mobile a').click(function(e) {
        e.preventDefault();
        var link = $(this).attr('href').substr(1);
        mobileNavPanel.removeClass('open');
        scrollToAnchor(link);
    });
    $('.back-to-top').click(function(e) {
        e.preventDefault();
        scrollToAnchor('top');
    });
});


$(window).resize(adaptNav);
$(window).scroll(showTop)

